import React from 'react';

import css from './TopbarSearchForm.module.css';

const IconSearchDesktop = () => (
<svg     className={css.iconSvg} width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.8926 12L1.50392 17.2411" stroke="#4A4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 26 1)" stroke="#4A4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);

export default IconSearchDesktop;
